@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

body{
  font-family: "Roboto", sans-serif;
}

.App {
  text-align: center;
}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



.form-select:focus {
  box-shadow: none;
}




.rounded-2 {
  border-radius: 0.5rem !important;
}

.set-border {
  border-start-start-radius: 0px !important;
    border-end-start-radius: 0px !important;
}

input, textarea,select{
  outline: none !important;
  outline-offset: 0 !important;
}




p{
  margin: 0;
  padding: 0;
}

h5{
  margin: 0;
  padding: 0;
  font-weight: 600;
}

.set-shadow{
  /* box-shadow: 1px 3px black; */
}

.set-padding {
  padding-left: 260px !important; 
}



select{
  border: 0px !important;
}

.bg-warning{
  background-color: #EBEBEB !important;
}

h6{
  margin-bottom: 0px !important;
}


g text{
  font-size: 13px;
  /* white-space: wrap; */
}




/* Media query for mobile screens */
@media screen and (max-width: 767px) {
  .set-padding {
    padding-left: 10px !important;
  }
}